import { protectedApi, serializeQuery } from 'services/axios';
import serviceUrl from 'services/serviceUrlList';

export const getProductCollections = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.collection.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getProductCollectionsMini = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.collection.mini + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getProductQuestions = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.question.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateProductQuestion = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.question.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getDynamicProductApi = async api => {
  try {
    const response = await protectedApi.get(serviceUrl.product[api].list);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getAppliesToList = async ({ api, search }) => {
  try {
    const query = `?search=${search}`;
    const response = await protectedApi.get(
      serviceUrl.product[api].mini + query
    );
    return response;
  } catch (error) {
    console.log('ERROR : ', error);
    return error.response;
  }
};

export const getProduct = async ({ filter, search }) => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.product.order +
        '?limit=50' +
        filter +
        '&search=' +
        search
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getTaxonomy = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.taxonomy.list + serializeQuery(query)
    );
    return response.data.results;
  } catch (error) {
    return undefined;
  }
};
export const getProductTags = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.tag.mini + serializeQuery(query)
    );
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};

export const createTag = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.tag.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createTagFromContent = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.tagFromContent.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateTagFromContent = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.tagFromContent.detail,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const checkTagFromContent = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.tagFromContent.check,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteTagFromContent = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.tagFromContent.delete,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getTagsMini = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.tag.mini + serializeQuery(query)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getTags = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.tag.list + serializeQuery(query)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateTag = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.tag.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteTag = async (user_id, tag_id) => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.tag.detail + tag_id,
      { data: { updated_by: user_id } }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteProduct = async (user_id, product_id) => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.product.detail + product_id,
      { data: { updated_by: user_id } }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getProductList = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.product.list + serializeQuery(query)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getProductListMini = async (query = {}) => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.product.mini + serializeQuery(query)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateProduct = async (payload, slug) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.product.detail + slug,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const productBulkAction = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.bulk_actions,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const productBulkPriceUpdate = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.product.bulk.prices,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getBrands = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.brand.list + serializeQuery(query)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createBrand = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.brand.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getBrand = async id => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.brand.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateBrand = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.brand.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteBrand = async (user_id, brand_id) => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.brand.detail + brand_id,
      { data: { updated_by: user_id } }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getBrandsMini = async () => {
  try {
    const response = await protectedApi.get(serviceUrl.product.brand.mini);
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};

export const getServingUnits = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.servingUnits.list + serializeQuery(query)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createServingUnit = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.servingUnits.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateServingUnit = async (payload, id) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.servingUnits.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteServingUnit = async (user_id, brand_id) => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.servingUnits.detail + brand_id,
      { data: { updated_by: user_id } }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getServingUnitMini = async () => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.servingUnits.mini
    );
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};
export const getAttributes = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.attribute.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getProductCategories = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.productCategory.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getProductCategoriesMini = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.productCategory.mini + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getProductCategory = async id => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.productCategory.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateProductCategory = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.productCategory.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteProductCategory = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.productCategory.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createProductCategory = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.productCategory.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getGiftCards = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.giftcard.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getGiftCardThemes = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.giftcardtheme.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getVendors = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.vendor.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getVariants = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.variant.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createHomepageBanner = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.homepage.banner,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createHomepageFeed = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.homepage.feed,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getHomepageData = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.homepage.get,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getHeaderMenu = async () => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.homepage.headermenu.list
    );
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};

export const createHeaderMenu = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.homepage.headermenu.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateHeaderMenu = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.homepage.headermenu.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteHeaderMenu = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.homepage.headermenu.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createMainMenu = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.homepage.mainmenu.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateMainMenu = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.homepage.mainmenu.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getMainMenu = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.homepage.mainmenu.list + serializeQuery(queryObj)
    );
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};

export const deleteMainMenu = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.homepage.mainmenu.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getMenuMini = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.homepage.mainmenuitem.mini + serializeQuery(query)
    );
    return response.data.results;
  } catch (error) {
    return error.response;
  }
};

export const createMenuItem = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.homepage.mainmenuitem.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateMenuItem = async (id, payload) => {
  try {
    const response = await protectedApi.put(
      serviceUrl.product.homepage.mainmenuitem.detail + id,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getMenuItems = async query => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.homepage.mainmenuitem.list + serializeQuery(query)
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const deleteMenuItems = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.homepage.mainmenuitem.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPersonas = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.persona.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPersonasmini = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.persona.mini + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createPersona = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.persona.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deletePersona = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.persona.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const createGoal = async payload => {
  try {
    const response = await protectedApi.post(
      serviceUrl.product.goal.list,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getGoals = async queryObj => {
  try {
    const response = await protectedApi.get(
      serviceUrl.product.goal.list + serializeQuery(queryObj)
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteGoal = async id => {
  try {
    const response = await protectedApi.delete(
      serviceUrl.product.goal.detail + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
