import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import TableHeader from 'components/common/TableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingWrapper from 'components/loading/LoadingWrapper';
import AppContext, { LoaderContext } from 'context/Context';
import { saveAs } from 'file-saver';
import { currencyFormat, formatNumber } from 'helpers/utils';
import moment from 'moment';
import {
  cancelFullfillment,
  shipmentLabelGenerate,
  updateOrder
} from 'pages/product/api';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import * as XLSX from 'xlsx';

import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Modal,
  Nav,
  NavDropdown,
  Row
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaChevronDown } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryReducer } from 'reducers/queryReducer';
import { getApiBase } from 'services/config';
import { getOrders } from 'services/order';

import OrderTableFilter from './module/OrderTableFilter';
import SoftBadge from 'components/common/SoftBadge';

const OrderList = () => {
  const { setLoading } = useContext(LoaderContext);
  const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState();
  const [cancelDescription, setCancelDescription] = useState('');
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const initialForm = {
    cancelDescription: ''
  };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: initialForm });

  const [sort, setSort] = useState({
    direction: 'none',
    accessor: ''
  });
  const [queryObj, setQueryObj] = useState({
    limit: 10,
    offset: 0,
    status__in: '1,3'
  });
  const { data, isLoading, refetch } = useQuery(
    ['getOrders', queryObj, sort],
    () =>
      getOrders({
        ...queryObj,
        ordering:
          sort.direction !== 'DESC' ? sort.accessor : '-' + sort.accessor
      }),
    {
      keepPreviousData: false,
      staleTime: Infinity
    }
  );
  const {
    config: { isDark }
  } = useContext(AppContext);

  const closeCancelOrderModal = () => {
    setIsCancelOrderModalOpen(false);
  };

  const handleFilter = (key, value) => {
    if (value === null) {
      // eslint-disable-next-line no-unused-vars
      const { [key]: omit, ...rest } = queryObj;
      setQueryObj(rest);
      return;
    }
    setQueryObj(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSearch = value => {
    setQueryObj(prev => ({
      ...prev,
      search: value
    }));
  };

  const navigate = useNavigate();

  const handleCustomerDetail = (id, isAnonymous) => {
    isAnonymous
      ? navigate(`/customer/${id}?anonymous=${isAnonymous}`)
      : navigate(`/customer/${id}`);
  };

  // ss pagination start
  const [{ pageIndex, pageSize, totalCount }, dispatch] = useReducer(
    queryReducer,
    { pageIndex: 0, pageSize: 10, totalCount: data?.data.count }
  );
  useEffect(() => {
    setQueryObj({
      ...queryObj,
      limit: pageSize,
      offset: pageSize * pageIndex
    });
  }, [pageSize, pageIndex, totalCount]);

  useEffect(() => {
    if (!data?.data.count) return;
    dispatch({
      type: 'TOTAL_COUNT_CHANGED',
      payload: data?.data.count
    });
  }, [data?.data.count]);
  // ss pagination end

  const handlePrintLabel = async id => {
    setLoading(true);
    const row =
      data.data.results.filter(dt => dt.id === id).length > 0 &&
      data.data.results.filter(dt => dt.id === id)[0];
    if (row.isPdfCargo) {
      window.open(
        `${getApiBase()}product/static/hepsijet_pdf/${row.id}-${
          row.deliveryCode
        }.pdf`
      );
    } else {
      const response = await shipmentLabelGenerate(id);
      if (response.status === 200) {
        window.open(
          `${getApiBase()}product/static/hepsijet_pdf/${id}-${
            row.deliveryCode
          }.pdf`
        );
      } else if (response.status === 400) {
        toast.error(response.data.message, { theme: 'colored' });
      } else {
        toast.error('An error occurred while generating the label', {
          theme: 'colored'
        });
      }
    }
    refetch();
    setLoading(false);
  };
  const handleBulkLabelPrint = async ids => {
    const ids_promise = ids.map(async id => {
      handlePrintLabel(id);
    });

    await Promise.all(ids_promise);
  };
  const handleBulkCancelOrder = async ids => {
    const ids_promise = ids.map(async id => {
      handleCancelOrder(id);
    });

    await Promise.all(ids_promise);
  };
  const handleBulkCancelFulfillment = async ids => {
    const ids_promise = ids.map(async id => {
      const deliveryCode = data?.data.results.filter(row => row.id === id)[0][
        'deliveryCode'
      ];
      handleCancelFulfillment(deliveryCode, id);
    });

    await Promise.all(ids_promise);
  };

  const onModalSubmit = () => {
    handleCancelOrder(selectedRowId);
  };

  const handleCancelOrder = async id => {
    closeCancelOrderModal();
    if (!(data.status !== 1 && data.status !== 2)) {
      toast.error('Completed orders cannot be cancelled', {
        theme: 'colored'
      });
      return;
    }
    const response = await updateOrder(
      { status: 7, cancelDescription: cancelDescription },
      id
    );
    if (response.status === 200) {
      toast.success('Order cancelled', { theme: 'colored' });
      refetch();
    } else {
      toast.error('Order cannot be cancelled', { theme: 'colored' });
    }
  };

  const handleCancelFulfillment = async (deliveryCode, id) => {
    if (!deliveryCode) {
      toast.error(
        `Delivery Code does not exist, check the status! #${id && id}`,
        {
          theme: 'colored'
        }
      );
      return;
    }
    const response = await cancelFullfillment(deliveryCode);
    if (response.status === 200) {
      toast.success(`Delivery is cancelled #${id && id}`, { theme: 'colored' });
    } else {
      toast.error(`Delivery cannot be cancelled #${id && id}`, {
        theme: 'colored'
      });
    }
  };

  const handleExportExcel = async () => {
    setIsLoadingExport(true);
    try {
      const response = await getOrders({ limit: 100000 });
      const dt = response?.data ?? undefined;
      const header = [];
      const itemHeader = ['ID', 'SKU', 'Name', 'Price', 'Quantity'];
      const customerHeader = ['Customer', 'Email', 'Address', 'Phone'];
      columns.forEach(column => {
        if (
          column.Header?.toString() !== 'Items' &&
          column.Header?.toString() !== 'Actions'
        ) {
          if (column.Header?.toString() === 'Customer') {
            header.push(...customerHeader);
          } else {
            header.push(column.Header?.toString() ?? '');
          }
        }
      });

      for (let i = 0; i < dt.max_count; i++) {
        itemHeader.forEach(item => {
          header.push(`Item_${i + 1}_${item}`);
        });
      }

      const body = [];
      dt?.results.forEach(row => {
        const tempRow = [];
        columns.forEach(column => {
          let value = null;
          if (column.accessor === 'created_at') {
            value = moment(row[column.accessor]).format('DD/MM/YYYY');
          } else if (column.accessor === 'sale_channel') {
            value = row[column.accessor] === 0 ? 'WEB' : 'mWEB';
          } else if (column.accessor === 'total_amount') {
            value = `${currencyFormat(row.total_amount)} ₺`;
          } else if (column.accessor === 'delivery_method') {
            value = `${currencyFormat(row.shipment_cost ?? 0)} ₺`;
          } else if (column.accessor === 'payment_status') {
            value =
              row.payment_status === 1
                ? 'Unpaid'
                : row.payment_status === 2
                ? 'Paid'
                : row.payment_status === 3
                ? 'Refunded'
                : 'Partially Refunded';
          } else if (column.accessor === 'status') {
            value =
              row.status === 1
                ? 'New'
                : row.status === 1
                ? 'New'
                : row.status === 2
                ? 'Shipped'
                : row.status === 3
                ? 'In Progress'
                : row.status === 4
                ? 'Delivered'
                : row.status === 5
                ? 'Cancelled'
                : row.status === 6
                ? 'Returned'
                : row.status === 7
                ? 'Declined'
                : 'Closed';
          } else if (column.accessor === 'user_full_name') {
            value = [
              row.user_full_name,
              row.user_email,

              !row.shipment_address
                ? 'Unkown Address'
                : `${row.shipment_address.district}, ${row.shipment_address.city}`,
              row.user_phone
            ];
          } else if (
            column.accessor === 'items' ||
            column.accessor === 'none'
          ) {
            return;
          }

          if (Array.isArray(value)) {
            tempRow.push(...value);
          } else {
            tempRow.push(value ?? row[column.accessor]);
          }
        });
        // add items to the row
        row.order_items.forEach(item => {
          tempRow.push(
            ...[
              item.product_id,
              item.sku,
              item.product_name,
              `${currencyFormat(item.product_price ?? 0)} ₺`,
              item.quantity
            ]
          );
        });

        body.push(tempRow);
      });

      const worksheet = XLSX.utils.aoa_to_sheet([header, ...body]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        `Order ${moment().format('DD.MM.YYYY')}.xlsx`
      );

      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setIsLoadingExport(false);
    }
  };

  const columns = [
    {
      accessor: 'id',
      Header: 'Order',
      headerProps: { className: 'pe-1 text-start' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Link to={`/order/order-detail/${id}`}>
            <Flex alignItems="center">
              <div className="flex-1">
                <h5 className="mb-0 fs--1">#{id}</h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      cellProps: { className: 'text-center' },
      Cell: rowData =>
        moment(rowData.row.original.created_at).format('DD.MM.YYYY HH:mm')
    },
    {
      accessor: 'user_full_name',
      Header: 'Customer',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'text-start' },
      Cell: rowData => {
        const {
          user_full_name,
          shipment_address,
          user_order_count,
          user_email,
          user_id,
          user_phone,
          isPublicOrder
        } = rowData.row.original;
        return (
          <Nav className="text-center d-block">
            <NavDropdown
              title={
                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <span>{user_full_name}</span>
                  <FaChevronDown size={'10px'} />
                </div>
              }
            >
              <div style={{ paddingLeft: 16, width: 250 }}>
                <h6>
                  <b>{user_full_name}</b>
                </h6>
                <h6>
                  {!shipment_address
                    ? 'Unkown Address'
                    : `${shipment_address.district}, ${shipment_address.city}`}
                </h6>
                <h6>{user_order_count} order</h6>
                <a href="#!">{user_email}</a>
                <NavDropdown.Divider />
                <Button
                  variant="outline-primary"
                  size="sm"
                  style={{ marginTop: 5 }}
                  onClick={() =>
                    handleCustomerDetail(
                      isPublicOrder ? user_phone : user_id,
                      isPublicOrder
                    )
                  }
                >
                  View Customer
                </Button>
              </div>
            </NavDropdown>
          </Nav>
        );
      }
    },
    {
      accessor: 'isPublicOrder',
      Header: 'Is Member',
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { isPublicOrder } = rowData.row.original;
        if (isPublicOrder) {
          return (
            <SoftBadge pill bg="success" className="d-block">
              Member
            </SoftBadge>
          );
        }
        return (
          <SoftBadge pill bg="danger" className="d-block">
            Non-Member
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'sale_channel',
      Header: 'Channel',
      headerProps: { className: 'pe-1 text-center', width: 100 },
      disableSortBy: true,
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { sale_channel } = rowData.row.original;
        return sale_channel === 0 ? 'WEB' : 'mWEB';
      }
    },
    {
      accessor: 'items',
      Header: 'Items',
      headerProps: { className: 'pe-1 text-center', width: 80 },
      disableSortBy: true,
      Cell: rowData => {
        const { order_items } = rowData.row.original;
        const item_count = order_items.reduce(
          (sum, dt) => sum + dt.quantity,
          0
        );
        return (
          <Nav className="text-center d-block">
            <NavDropdown
              title={
                <div className="d-flex gap-2 align-items-center justify-content-center">
                  <span>
                    {item_count > 1 ? `${item_count} items` : '1 item'}
                  </span>
                  <FaChevronDown size={'10px'} />
                </div>
              }
            >
              <div
                style={{
                  padding: '8px 0px 0px 8px',
                  width: 300,
                  maxHeight: 306,
                  overflowY: 'auto',
                  overflowX: 'hidden'
                }}
              >
                {order_items.map(dt => (
                  <Row key={dt.id} style={{ minHeight: 80 }}>
                    <Col xs={2}>
                      <div style={{ position: 'relative' }}>
                        <div className="circle-number">{dt.quantity}</div>
                        <Image
                          style={{
                            padding: '4px',
                            border: '1px solid #dde0e4',
                            borderRadius: '8px',
                            objectFit: 'contain',
                            maxHeight: 38,
                            maxWidth: 38
                          }}
                          src={dt.product_photo?.file}
                          width={38}
                          height={38}
                          alt={dt.product_name}
                        />
                      </div>
                    </Col>
                    <Col xs={9} style={{ marginLeft: 10 }}>
                      <a href={`/product/product-update/${dt.slug}`}>
                        {dt.product_name}
                      </a>
                      <h6>SKU {dt.sku}</h6>
                    </Col>
                  </Row>
                ))}
              </div>
            </NavDropdown>
          </Nav>
        );
      }
    },
    {
      accessor: 'discount_title',
      Header: 'Discount Title',
      headerProps: { className: 'pe-1 text-center', width: 50 },
      disableSortBy: true,
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { discount_title } = rowData.row.original;
        return <span className="max-1-line">{discount_title}</span>;
      }
    },
    {
      accessor: 'discount_amount',
      Header: 'Discount Amount',
      headerProps: { className: 'pe-1 text-center', width: 50 },
      disableSortBy: true,
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { discount_amount } = rowData.row.original;
        return `${currencyFormat(discount_amount)} ₺`;
      }
    },
    {
      accessor: 'total_amount',
      Header: 'Total',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'py-2 text-center' },
      Cell: rowData => {
        const { total_amount } = rowData.row.original;
        return `${currencyFormat(total_amount)} ₺`;
      }
    },
    {
      accessor: 'payment_status',
      Header: 'Payment',
      headerProps: { className: 'pe-1 text-start', width: 100 },
      disableSortBy: true,
      cellProps: { className: 'text-start' },
      Cell: rowData => {
        const { payment_status } = rowData.row.original;

        return payment_status === 1
          ? 'Unpaid'
          : payment_status === 2
          ? 'Paid'
          : payment_status === 3
          ? 'Refunded'
          : 'Partially Refunded';
      }
    },
    {
      accessor: 'refund_amount',
      Header: 'Refund Amount',
      headerProps: { className: 'pe-1 text-center' },
      cellProps: { className: 'py-2 text-center' },
      Cell: rowData => {
        const { refund_amount } = rowData.row.original;
        return `${currencyFormat(refund_amount)} ₺`;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      headerProps: { className: 'pe-1 text-start', width: 50 },
      disableSortBy: true,
      cellProps: { className: 'text-start' },
      Cell: rowData => {
        const { status } = rowData.row.original;

        switch (status) {
          case 1:
            return 'New';
          case 2:
            return 'Shipped';
          case 3:
            return 'In Progress';
          case 4:
            return 'Delivered';
          case 5:
            return 'Cancelled';
          case 6:
            return 'Returned';
          case 7:
            return 'Declined';
          case 8:
            return 'Closed';
          default:
            break;
        }
      }
    },
    {
      accessor: 'delivery_method',
      Header: 'Shipment',
      headerProps: { className: 'pe-1 text-start', width: 50 },
      disableSortBy: true,
      Cell: rowData => {
        const { is_shipment_free, shipment_cost } = rowData.row.original;
        if (is_shipment_free) {
          return <span className="text-end d-block">{formatNumber(0)} TL</span>;
        }
        return (
          <span className="text-end d-block">
            {formatNumber(shipment_cost)} TL
          </span>
        );
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      headerProps: { className: 'pe-1 text-end' },
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: dt => {
        const { id, deliveryCode } = dt.row.original;
        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item onClick={() => handlePrintLabel(id)}>
                Print Shipment Label
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleCancelFulfillment(deliveryCode)}
              >
                Cancel Fulfillment
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setSelectedRowId(id);
                  setIsCancelOrderModalOpen(true);
                }}
              >
                Cancel Order
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <div className="p-2">
      <LoadingWrapper loading={isLoading}>
        <AdvanceTableWrapper
          columns={columns}
          data={data?.data.results || []}
          selection
          sortable
          pagination
          perPage={queryObj.limit}
          queryPageIndex={pageIndex}
          totalCount={totalCount}
          dispatch={dispatch}
        >
          <Card className="mb-3">
            <Card.Header>
              <TableHeader
                title="Orders"
                handleRefetch={refetch}
                table
                bulkTable={'Order'}
                bulkActive={false}
                bulkArchive={false}
                bulkDelete={false}
                bulkExtra={[
                  {
                    label: 'Print Shipment Label',
                    value: 'label_print',
                    type: 'print',
                    callBack: handleBulkLabelPrint
                  },

                  {
                    label: 'Cancel fulfillment',
                    value: 'cancel_fulfillment',
                    callBack: handleBulkCancelFulfillment
                  },
                  {
                    label: 'Cancel Order',
                    value: 'cancel_order',
                    callBack: handleBulkCancelOrder
                  }
                ]}
                isBulkButton={false}
              />
              <OrderTableFilter
                handleSearch={handleSearch}
                handleFilter={handleFilter}
                handleExport={handleExportExcel}
                isLoadingExport={isLoadingExport}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle text-center"
                rowClassName="align-middle"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                sort={sort}
                setSort={setSort}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </LoadingWrapper>

      <Modal
        show={isCancelOrderModalOpen}
        onHide={closeCancelOrderModal}
        contentClassName="border"
      >
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Order Cancel Description</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onModalSubmit)}>
          <Modal.Body className="p-card">
            <Form.Group className="mb-3" controlId="cancelDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                {...register('cancelDescription', { required: true })}
                isInvalid={errors.cancelDescription}
                type="text"
                placeholder="Cancel Description"
                onChange={e => setCancelDescription(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                This field cannot be empty!
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="danger"
              className="px-3 mx-2"
              onClick={closeCancelOrderModal}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit" className="px-5 mx-0">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default OrderList;
